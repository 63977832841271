import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


import SEO from "../components/seo"


const settings = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
}


const IndexPage = (props) => (
  <Layout>
    <SEO title="Home"/>
    <div  style={{
      margin: `0 auto`,
      maxWidth: 1200,
      paddingTop: `1.0875rem`,
      backgroundColor: 'white',
      textAlign: "center"
    }}>
      <p>HDA Services provides bespoke software solutions that
        streamline processes for traditionally time consuming administrative tasks.</p>
      <p>Easy data capture and simple presentation underpin a
        philosophy that it is the data that drives any improvement.</p>
      <p>All our solutions are cloud based in London, with secured and encrypted
        daily backup. Data in transit is secured using industry standard encryption
        and we offer 2-factor authentication for all user accounts.
         </p>

    </div>
    <div style={{
      paddingTop: `1.0875rem`,
      height: 75,
      backgroundColor: 'silver'
    }}>
      <div style={{
        margin: '0 auto',
        maxWidth: 1200,
      }}>
        <a style={{
          marginLeft: 10,
          marginRight: 10,
          fontSize: 'xx-large',
          fontWeight: 'bold',
          color: 'black'}}
           href={"https://cdiary.online"} className={"inline-link"}>cDiary.online</a>
      </div>
    </div>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1200,
        backgroundColor: 'white',
        textAlign: "center",
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <div className={"c-grid"}>

        <div className={"c-grid-item text reverse"}>

          <div className={"explanation-text"}>
            <p>A logbook for anaesthetists to track NHS work against your job plan, record private practice & CPD activities</p>
            <ul className={'reduced-margin'}>
              <li>Responsive design for enhanced desktop and mobile experience</li>
              <li>View progress for flexible work components</li>
              <li>Individually customizable options for procedures & airways</li>
              <li>Record cases, CPD activities, reflections & complications</li>
              <li>Create & download individual reports for your appraisal</li>
              <li>Track payments for additional NHS & private work</li>
            </ul>

          </div>
        </div>
        <div className={'c-grid-c reverse'}>
          <Slider {...settings} className={'productSlider'}>
            <div>
              <Img objectFit="cover"
                   objectPosition="50% 50%"

                   fluid={props.data.cdiaryOne.childImageSharp.fluid}/>
            </div>
            <div>
              <Img objectFit={'contain'} fluid={props.data.cdiaryTwo.childImageSharp.fluid}/>
            </div>
            <div>
              <Img objectFit={'contain'} fluid={props.data.cdiaryThree.childImageSharp.fluid}/>
            </div>
          </Slider>
        </div>
      </div>
    </div>


    <div style={{
      paddingTop: `1.0875rem`,
      height: 75,
      backgroundColor: 'silver'
    }}>
      <div style={{
        margin: '0 auto',
        maxWidth: 1200,
      }}>
        <a style={{
          marginLeft: 10,
          marginRight: 10,
          fontSize: 'xx-large',
          fontWeight: 'bold',
          color: 'black'}}
           href={"https://dr-feedback.online"} className={"inline-link"}>dr-feedback.online</a>
      </div>
    </div>

    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1200,
        backgroundColor: 'white',
        textAlign: "center",
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <div className={"c-grid reverse"}>
        <div className={"c-grid-item text"}>
          <div className={"explanation-text"}>
            <p>A feedback system using the same questions and response options as the Royal College of Anaesthetists
              patient feedback questionnaire.</p>
            <ul className={'reduced-margin'}>
              <li>Responsive design for enhanced desktop and mobile experience</li>
              <li>Completely anonymised feedback</li>
              <li>Ability to separate feedback for different patient groups (e.g. private vs NHS)</li>
              <li>Simple invitation letters with QR codes that automatically enter feedback code</li>
              <li>Create & download individual reports for your appraisal</li>
            </ul>
          </div>
        </div>

        <div className={'c-grid-c reverse'} >
          <Slider {...settings} className={'productSlider'}>
            <div>
              <Img fluid={props.data.feedbackThree.childImageSharp.fluid}/>
            </div>
            <div>
              <Img fluid={props.data.feedbackTwo.childImageSharp.fluid}/>
            </div>
            <div>
              <Img fluid={props.data.feedbackOne.childImageSharp.fluid}/>
            </div>
          </Slider>
        </div>

      </div>


    </div>


    <div className={'footer'}>
      <div>HDA Services Limited is registered at Companies House (Registration Number 11085685).</div>
      <div>More information about this site can be found <Link to={"/about"} className={'has-text-weight-bold hover-link'}>here</Link></div>

    </div>

  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
query {
cdiaryOne: file(relativePath: { eq: "cdiary-diary.jpg" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}
cdiaryTwo: file(relativePath: { eq: "cdiary-progress.jpg" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}
cdiaryThree: file(relativePath: { eq: "cdiary-reports.jpg" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}
feedbackOne: file(relativePath: { eq: "dr-feedback-give-feedback-desktop.png" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}
feedbackTwo: file(relativePath: { eq: "dr-feedback-give-feedback-mobile.png" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}
feedbackThree: file(relativePath: { eq: "dr-feedback-show-feedback.jpg" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}


}
`

